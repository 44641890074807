import React, { useState, useEffect } from 'react'
import { FormattedMessage } from '@platform/cj-platform-navigation'
import moment from 'moment-timezone'
import { Panel, Body, Footer, Header } from '@cjdev-internal/visual-stack-x/Panel'
import { Form, Field, FieldContent, ChoiceInput } from '@cjdev-internal/visual-stack-x/legacy/Form'
import { SelectField } from '@cjdev-internal/visual-stack-x/legacy/Form'
import { Select } from '@cjdev-internal/visual-stack-x/Select'
import { Button } from '@cjdev-internal/visual-stack-x/Button'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import './PreferencesForm.css'
import useApi from '../PlatformApi'

export default function PreferencesForm({ user }) {
  const [language, setLanguage] = useState(user?.language)
  const [preferredDateFormat, setPreferredDateFormat] = useState(user?.preferredDateFormat)
  const [timezone, setTimezone] = useState(user?.timezone)
  const [timeFormat, setTimeFormat] = useState(user?.timeFormat)

  const api = useApi()

  useEffect(() => {
    setLanguage(user?.language)
    setPreferredDateFormat(user?.preferredDateFormat)
    setTimezone(user?.timezone)
    setTimeFormat(user?.timeFormat)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const cancelEdit = () => {
    setLanguage(user?.language)
    setPreferredDateFormat(user?.preferredDateFormat)
    setTimezone(user?.timezone)
    setTimeFormat(user?.timeFormat)
  }

  const handleDateFormatSelect = ({ value }) => {
    setPreferredDateFormat(value)
  }

  const handleTimezoneSelect = ({ value }) => {
    setTimezone(value)
  }

  const handleTimeFormatRadio = (e) => {
    setTimeFormat(e.target.value)
  }

  const handleTimezoneCheckbox = (e) => {
    if (e.target.checked) {
      setTimezone(null)
    } else {
      setTimezone(user?.timezone || moment.tz.guess())
    }
  }

  const save = async () => {
    if (!user.isEmployee) {
      const newPreferences = { language, preferredDateFormat, timezone: timezone || '', timeFormat }
      await api.savePreferences(user.userId, newPreferences)
      window.location.reload()
    }
  }

  return user ? (
    <span className="preferences-content">
      <Panel>
        <Header>
          <FormattedMessage defaultMessage="Preference" />
          {user.isEmployee ? ' (Disabled for Employees)' : ''}
        </Header>
        <Body>
          <Form vertical={true}>
            <TimezoneSelect
              timezone={timezone}
              onChange={handleTimezoneSelect}
              onCheckboxChange={handleTimezoneCheckbox}
              disabled={user.isEmployee}
            />
            <TimeFormatRadio
              timeFormat={timeFormat}
              onChange={handleTimeFormatRadio}
              disabled={user.isEmployee}
            />
            <SelectField
              id="date-format-select"
              data-testid="date-format-select"
              label={<FormattedMessage defaultMessage="Date Format" />}
              options={dateFormats}
              value={dateFormats.find(({ value }) => value === preferredDateFormat)}
              onChange={handleDateFormatSelect}
              isDisabled={user.isEmployee}
            />
          </Form>
        </Body>
        <Footer>
          <Row gap={'medium'}>
            <Button type="primary" onClick={save} disabled={user.isEmployee}>
              <FormattedMessage defaultMessage="Update Preferences" />
            </Button>
            <Button type="tertiary" onClick={cancelEdit} disabled={user.isEmployee}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </Row>
        </Footer>
      </Panel>
    </span>
  ) : (
    <></>
  )
}

const TimezoneSelect = ({ timezone, onChange, onCheckboxChange, disabled }) => (
  <div className="vs-field">
    <label htmlFor="timezone-select">
      <FormattedMessage defaultMessage="Time Zone" />
    </label>
    <div data-testid="timezone-edit">
      <label data-testid="timezone-checkbox-label">
        <input
          data-testid="timezone-checkbox"
          type="checkbox"
          checked={!timezone}
          onChange={onCheckboxChange}
          disabled={disabled}
        />
        <FormattedMessage defaultMessage="Use my local timezone" />
      </label>
      {timezone && (
        <span data-testid="timezone-select">
          <Select
            id="timezone-select"
            options={timezones}
            value={timezones.find(({ value }) => value === timezone)}
            onChange={onChange}
            disabled={disabled}
          />
        </span>
      )}
    </div>
  </div>
)

const TimeFormatRadio = ({ timeFormat, onChange, disabled }) => (
  <Field
    id="time-format-24hr"
    data-testid="time-format-24hr-radio"
    label={<FormattedMessage defaultMessage="Time Format" />}
  >
    <FieldContent>
      <ChoiceInput
        name="time-format"
        label={<FormattedMessage defaultMessage="24 Hour Time" />}
        value="24hr"
        checked={timeFormat === '24hr'}
        onChange={onChange}
        disabled={disabled}
      />
      <ChoiceInput
        name="time-format"
        label={<FormattedMessage defaultMessage="Display AM / PM" />}
        value="12hr"
        checked={timeFormat === '12hr'}
        onChange={onChange}
        disabled={disabled}
      />
    </FieldContent>
  </Field>
)

const timezones = Array.from(moment.tz.names(), (k) => ({ label: k, value: k }))

const dateFormats = Array.from(
  new Map([
    ['d-MMM-yyyy', 'd-MMM-yyyy (19-Mar-2020)'],
    ['MM-dd-yyyy', 'MM-dd-yyyy (03-19-2020)'],
    ['dd-MM-yyyy', 'dd-MM-yyyy (19-03-2020)']
  ]),
  ([v, l]) => ({ value: v, label: l })
)
