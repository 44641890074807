// ---------------------------------------------------------------
// NOTE: keep this list in sync with scripts/supportedLanguages.js
// ---------------------------------------------------------------
import cs from './compiled/cs.json'
import en from './compiled/en.json'
import es from './compiled/es.json'
import de from './compiled/de.json'
import fr from './compiled/fr.json'
import ja from './compiled/ja.json'
import pl from './compiled/pl.json'
import pt from './compiled/pt.json'
import zh from './compiled/zh.json'

const translations = { cs, en, es, de, fr, ja, pl, pt, zh }
export default translations
