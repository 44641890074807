import './RedirectPage.css'
import { FormattedMessage } from '@platform/cj-platform-navigation'
import React, { useEffect } from 'react'
import { Link } from '@cjdev-internal/visual-stack-x/Link'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
export const RedirectPage = ({ url }) => {
  useEffect(() => {
    window.location.assign(url)
  }, [url])

  return (
    <div className={'redirect'}>
      <Text size={14}>
        <FormattedMessage defaultMessage="This page has moved! If you aren't automatically redirected in a few seconds, you can click on this link directly." />
      </Text>
      <Link href={url}>{url}</Link>
    </div>
  )
}
