import React, { useState, useEffect } from 'react'
import { FormattedMessage } from '@platform/cj-platform-navigation'
import { useAlert } from '@cjdev-internal/visual-stack-x/Alert'
import { Panel, Body, Footer } from '@cjdev-internal/visual-stack-x/Panel'
import { Button } from '@cjdev-internal/visual-stack-x/Button'
import UserProfilePersonalInfo from './UserProfilePersonalInfo'
import UserProfilePasswordForm from './UserProfilePasswordForm'
import useApi from '../PlatformApi'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import { Stack } from '@cjdev-internal/visual-stack-x/Stack'

export default function UserProfile({ user, ...props }) {
  const [curFirstName, setCurFirstName] = useState('')
  const [curLastName, setCurLastName] = useState('')
  const [mount, showAlert, closeAlert] = useAlert()
  const showCustomAlert = (alertProps) => showAlert(alertProps)
  const api = useApi()

  useEffect(() => {
    setCurFirstName(user?.firstName)
    setCurLastName(user?.lastName)
  }, [user])

  const save = async () => {
    try {
      const newUser = { firstName: curFirstName, lastName: curLastName }
      await api.saveUser(user.userId, newUser)
      document.dispatchEvent(new Event('userUpdated'))
      showCustomAlert({
        type: 'success',
        closeType: 'icon',
        children: <FormattedMessage defaultMessage="Success! Personal information updated." />,
        onClose: closeAlert
      })
    } catch (e) {
      showCustomAlert({
        type: 'warning',
        closeType: 'icon',
        children: <FormattedMessage defaultMessage="Failed to update personal information." />,
        onClose: closeAlert
      })
      console.error(e)
    }
  }

  const cancelEdit = () => {
    setCurFirstName(user?.firstName)
    setCurLastName(user?.lastName)
  }

  return (
    <span className="user-profile-content">
      <Panel>
        <Body>
          {mount}
          <Stack paddingTop={'xl'}>
            <UserProfilePersonalInfo
              curFirstName={curFirstName}
              setCurFirstName={setCurFirstName}
              curLastName={curLastName}
              setCurLastName={setCurLastName}
              user={user}
              {...props}
            />
            <UserProfilePasswordForm user={user} {...props} />
          </Stack>
        </Body>
        <Footer>
          <Row gap={'medium'}>
            <Button type="primary" onClick={save}>
              <FormattedMessage defaultMessage="Update Profile" />
            </Button>
            <Button type="tertiary" onClick={cancelEdit}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </Row>
        </Footer>
      </Panel>
    </span>
  )
}
