import React, { useState } from 'react'
import { FormattedMessage } from '@platform/cj-platform-navigation'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import { Button } from '@cjdev-internal/visual-stack-x/Button'
import { useAlert } from '@cjdev-internal/visual-stack-x/Alert'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import { Stack } from '@cjdev-internal/visual-stack-x/Stack'
import { InputField } from '@cjdev-internal/visual-stack-x/InputField'
import Eye from 'mdi-react/EyeIcon'
import EyeOff from 'mdi-react/EyeOffIcon'
import CheckCircle from 'mdi-react/CheckCircleIcon'

// --------------------------------------------------
// see PasswordUtil.java for server-side rules
// --------------------------------------------------
const letters = /[a-z,A-Z]/
const numbers = /\d/
const special = /[!#$%&*+\-=?@_~]/

export const extractUsername = (email) => {
  const atIndex = email.indexOf('@')
  return email.slice(0, atIndex)
}

export const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const passwordContainsUsername = (password, email) => {
  const username = extractUsername(email)
  const escapedUsername = escapeRegExp(username)
  const usernameRegex = new RegExp(escapedUsername)

  return usernameRegex.test(password)
}

export function passwordComplexEnough(password) {
  const longEnough = (password || '').length >= 8

  const f = (acc, r) => acc + (r.test(password) ? 1 : 0)

  const score = [letters, numbers, special].reduce(f, 0)
  return longEnough && score > 1
}

export default function UserProfilePasswordForm({ initialReadOnly = true, user, savePassword }) {
  const [readOnly, setReadOnly] = useState(initialReadOnly)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const isNewPasswordValid =
    passwordComplexEnough(newPassword) && !passwordContainsUsername(newPassword, user.emailAddress)
  const enableSaveButton = currentPassword.trim() && isNewPasswordValid
  const [mount, showAlert, closeAlert] = useAlert()
  const showCustomAlert = (alertProps) => showAlert(alertProps)
  function switchToReadOnly() {
    setNewPassword('')
    setCurrentPassword('')
    setReadOnly(true)
  }

  function passwordChangeResult(response) {
    if (response.ok) {
      switchToReadOnly()
      showCustomAlert({
        type: 'success',
        closeType: 'icon',
        children: <FormattedMessage defaultMessage="Success! Password updated." />,
        onClose: closeAlert
      })
    } else {
      passwordChangeError()
    }
  }

  function passwordChangeError() {
    showCustomAlert({
      type: 'warning',
      closeType: 'icon',
      children: <FormattedMessage defaultMessage="Failed to update password" />,
      onClose: closeAlert
    })
  }

  async function changePassword() {
    const payload = {
      email: user.emailAddress,
      password: currentPassword,
      newPassword
    }
    try {
      const response = await savePassword(user.userId, payload)
      passwordChangeResult(response)
    } catch (e) {
      passwordChangeError()
      console.error(e)
    }
  }

  return (
    <Stack>
      {mount}
      <Text size={16} weight={'medium'}>
        <FormattedMessage defaultMessage="Password" />
      </Text>
      <Text color={'secondary'} size={13}>
        <FormattedMessage defaultMessage="Password Description" />
      </Text>
      {readOnly ? (
        <Row paddingTop={'xl'} gap={'medium'} align={'center'}>
          <Button
            data-testid="change-password"
            type="secondary"
            onClick={() => setReadOnly(false)}
            disabled={user?.isEmployee}
          >
            <FormattedMessage defaultMessage="Change Password" />
          </Button>
          {user?.isEmployee && (
            <Text color={'secondary'} size={13}>
              <FormattedMessage defaultMessage="CJ employees cannot change their password with this form" />
            </Text>
          )}
        </Row>
      ) : (
        <form>
          <Stack paddingBottom={'medium'}>
            <InputField
              label={<FormattedMessage defaultMessage="Current Password" />}
              id="current-password"
              value={currentPassword}
              onChange={setCurrentPassword}
              autoFocus={true}
              autoComplete={'current-password'}
              type={currentPasswordVisible ? 'text' : 'password'}
              secondaryHelp={
                currentPasswordVisible ? (
                  <Eye
                    data-testid="current-password-visibility"
                    onClick={() => setCurrentPasswordVisible(!currentPasswordVisible)}
                  />
                ) : (
                  <EyeOff
                    data-testid="current-password-visibility-off"
                    onClick={() => setCurrentPasswordVisible(!currentPasswordVisible)}
                  />
                )
              }
            />
            <InputField
              label={<FormattedMessage defaultMessage="New Password" />}
              id="new-password"
              autoComplete={'new-password'}
              value={newPassword}
              onChange={setNewPassword}
              help={
                isNewPasswordValid && newPassword ? (
                  <CheckCircle data-testid="valid-new-password-icon" />
                ) : (
                  <FormattedMessage defaultMessage="Password must be at least 8 characters long, cannot contain your email address and must contain at least two of the following categories: letters, numbers, and symbols" />
                )
              }
              type={newPasswordVisible ? 'text' : 'password'}
              secondaryHelp={
                newPasswordVisible ? (
                  <Eye
                    data-testid="new-password-visibility"
                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  />
                ) : (
                  <EyeOff
                    data-testid="new-password-visibility-off"
                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  />
                )
              }
            />
          </Stack>
          <Row gap={'medium'}>
            <Button
              type="primary"
              uppercase={true}
              disabled={!enableSaveButton}
              onClick={changePassword}
              data-testid="save-new-password"
            >
              <FormattedMessage defaultMessage="Save" />
            </Button>
            <Button type="tertiary" uppercase={true} onClick={switchToReadOnly}>
              <FormattedMessage defaultMessage="Cancel" />
            </Button>
          </Row>
        </form>
      )}
    </Stack>
  )
}
