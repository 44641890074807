import { getBearerToken } from '@platform/platform-auth'
import React, { useContext } from 'react'

const authenticatedFetch = async (url, options) => {
  const token = await getBearerToken()

  return fetch(url, {
    headers: {
      authorization: `Bearer ${token}`
    },
    ...options
  })
}

export const ApiContext = React.createContext(null)

const useApi = () => {
  const memberUrl = useContext(ApiContext)

  return {
    async savePassword(userId, payload) {
      const token = await getBearerToken()

      return await fetch(`${memberUrl}/affapi/users/${userId}/password`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
    },

    async saveUser(userId, data) {
      const endpoint = `${memberUrl}/affapi/oauth/user/${userId}`
      const token = await getBearerToken()

      const response = await fetch(endpoint, {
        body: JSON.stringify(data),
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (!response.ok) {
        throw new Error(response.status)
      }

      return data
    },

    async savePreferences(userId, preferences) {
      const endpoint = `${memberUrl}/affapi/users/${userId}/preferences`
      const token = await getBearerToken()

      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(preferences)
      })
      if (!response.ok) throw new Error(response.status)

      return response
    },

    putLinkPublishers(userId, payload) {
      return authenticatedFetch(`${memberUrl}/member/api/publisher/${userId}/linkPublishers`, {
        method: 'PUT',
        body: JSON.stringify(payload)
      })
    },

    putLinkAdvertisers(userId, payload) {
      return authenticatedFetch(`${memberUrl}/affapi/users/${userId}/account-linking`, {
        method: 'PUT',
        body: JSON.stringify(payload)
      })
    },

    deleteLinkAdvertisers(userId, selectedCompanies) {
      return authenticatedFetch(
        `${memberUrl}/affapi/users/${userId}/account-linking/${selectedCompanies}`,
        {
          method: 'DELETE'
        }
      )
    },

    deleteLinkPublishers(userId, selectedCompanies) {
      return authenticatedFetch(
        `${memberUrl}/member/api/publisher/${userId}/linkPublishers/${selectedCompanies}`,
        {
          method: 'DELETE'
        }
      )
    },

    async changePassword(emailAddress) {
      return await authenticatedFetch(`${memberUrl}/affapi/user/${emailAddress}/change_password`, {
        method: 'POST'
      })
    }
  }
}

export default useApi
