import React from 'react'
import { PlatformApp, defineMessage } from '@platform/cj-platform-navigation'
import UserProfile from './Components/UserProfile'
import PreferencesForm from './Components/PreferencesForm'
import { ApiContext } from './PlatformApi'
import '@cjdev-internal/design-tokens'
import './index.css'
import { RedirectPage } from './Components/RedirectPage'

export default function App({ translations }) {
  const routes = [
    {
      path: 'user-profile',
      titleMessage: defineMessage({ defaultMessage: 'Profile' }),
      Content: ({ user }) => <UserProfile user={user} />
    },
    {
      path: 'edit-profile',
      titleMessage: defineMessage({ defaultMessage: 'Edit Profile' }),
      Content: () => <RedirectPage url={'https://members.cj.com/member/app/profile/edit'} />
    },
    {
      path: 'profile-preferences',
      titleMessage: defineMessage({ defaultMessage: 'Preferences' }),
      Content: ({ user }) => <PreferencesForm user={user} />
    },
    {
      path: 'manage-accounts',
      titleMessage: defineMessage({ defaultMessage: 'Manage Accounts' }),
      Content: () => (
        <RedirectPage url={'https://members.cj.com/member/app/profile/manageAccounts'} />
      )
    }
  ]

  return (
    <ApiContext.Provider value={process.env.REACT_APP_MEMBERS_URL || 'https://members.cj.com'}>
      <PlatformApp
        routes={routes}
        translations={translations}
        platformDomains={getPlatformDomains()}
      />
    </ApiContext.Provider>
  )
}

const getPlatformDomains = () => {
  const members =
    window.location.hostname === 'profile.cj.com'
      ? undefined
      : process.env.REACT_APP_MEMBERS_ENV || 'lab106'

  return members ? { members } : undefined
}
