import React, { useEffect } from 'react'
import { FormattedMessage } from '@platform/cj-platform-navigation'
import { Button } from '@cjdev-internal/visual-stack-x/Button'
import { Text } from '@cjdev-internal/visual-stack-x/Text'
import Pendo from '@cjdev-internal/pendo'
import { InputField } from '@cjdev-internal/visual-stack-x/InputField'
import { Stack } from '@cjdev-internal/visual-stack-x/Stack'
import { Row } from '@cjdev-internal/visual-stack-x/Row'
import styles from './UserProfile.module.css'

export default function UserProfilePersonalInfo({
  user,
  curFirstName,
  setCurFirstName,
  curLastName,
  setCurLastName
}) {
  useEffect(() => {
    Pendo.install(window.location.protocol === 'https' ? 'production' : 'dev').initialize(
      user?.id,
      user?.email,
      false
    )
  }, [user])

  return user ? (
    <Stack>
      <Text size={16} weight={'medium'}>
        <FormattedMessage defaultMessage="Profile" />
      </Text>
      <Text color={'secondary'} size={13}>
        <FormattedMessage defaultMessage="Basic personal info that you use on the CJ network." />
      </Text>
      <Stack paddingTop={'xl'} paddingBottom={'xl'}>
        <InputField
          label={<FormattedMessage defaultMessage="First Name" />}
          id="first-name"
          value={curFirstName}
          onChange={setCurFirstName}
          className={styles.formInput}
        />
        <InputField
          label={<FormattedMessage defaultMessage="Last Name" />}
          id="last-name"
          value={curLastName}
          onChange={setCurLastName}
          className={styles.formInput}
        />
        <InputField
          label={<FormattedMessage defaultMessage="Email" />}
          id="email"
          value={user.emailAddress}
          onChange={setCurLastName}
          disabled={true}
          className={styles.formInput}
        />
        <Row>
          <Button type="secondary" disabled={true}>
            <FormattedMessage defaultMessage="Change Email" />
          </Button>
        </Row>
      </Stack>
    </Stack>
  ) : (
    <></>
  )
}
